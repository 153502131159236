import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Icon from "components/Icon";

import "./IconText.scss";

const IconText = ({ iconName, text, iconPosition, ...restProps }) => {

  const icon = (<Icon className={clsx("icon")} iconName={iconName} {...restProps} />)
  iconPosition = iconPosition === "left" ? iconPosition : "right";
  return (
    <span className="icon-text">
      {iconPosition === "left" ? icon : null}
      {text}
      {iconPosition === "right" ? icon : null}
    </span>
  );
};

IconText.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconPosition: PropTypes.string
};

IconText.defaultProps = {
  iconPosition: "right",
}

export default IconText;
