import makeFAIcon from "utils/makeFAIcon";

import {
  faPhone,
  faEnvelope,
  faPaperPlane,
  faPlus,
  faBars,
  faTimes,
  faGlobe,
  faHome,
  faCity,
  faIndustry,
  faGlobeAmericas,
  faHandsHelping,
  faChartLine,
  faSolarPanel,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faGithubAlt,
  faMediumM,
  faWhatsapp,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";


export const PhoneIcon = makeFAIcon(faPhone);
export const EnvelopIcon = makeFAIcon(faEnvelope);
export const PaperPlaneIcon = makeFAIcon(faPaperPlane);
export const PlusIcon = makeFAIcon(faPlus);
export const BarsIcon = makeFAIcon(faBars);
export const GithubIcon = makeFAIcon(faGithubAlt);
export const MediumIcon = makeFAIcon(faMediumM);
export const CloseIcon = makeFAIcon(faTimes);
export const LanguageIcon = makeFAIcon(faGlobe);
export const HomeIcon = makeFAIcon(faHome);
export const CityIcon = makeFAIcon(faCity);
export const IndustryIcon = makeFAIcon(faIndustry);
export const GlobeAmericasIcon = makeFAIcon(faGlobeAmericas);
export const HandsHelpingIcon = makeFAIcon(faHandsHelping);
export const ChartLineIcon = makeFAIcon(faChartLine);
export const SolarPanelIcon = makeFAIcon(faSolarPanel);
export const UsersIcon = makeFAIcon(faUsers);

export const TwitterIcon = makeFAIcon(faTwitter);
export const FacebookIcon = makeFAIcon(faFacebookF);
export const LinkedinIcon = makeFAIcon(faLinkedinIn);
export const WhatsAppIcon = makeFAIcon(faWhatsapp);
export const InstagramIcon = makeFAIcon(faInstagram);

export * from "config/CustomIcons";
